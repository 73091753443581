<template>
  <div class="v-slot-quarter-image-wrapper">
    <div
      v-for="(selectedSlot, key) in selectedSlots"
      :key="`${key}-${selectedSlot.ID}`"
      class="v-slot-quarter-image-single"
      itemscope
      itemtype="http://schema.org/ImageObject"
      @click="() => selectSlotGroup(key)"
    >
      <arora-nuxt-image
        v-if="imageExists(selectedSlot.CombineMobileImage)"
        :key="`CombineImage-${selectedSlot.ID}`"
        :alt="selectedSlot.Name"
        :image="selectedSlot.CombineMobileImage"
        :scale="2"
        image-type="CombineMobile"
      />
      <arora-nuxt-image
        v-else-if="imageExists(selectedSlot.NormalImage)"
        :key="`NormalImage-${selectedSlot.ID}`"
        :alt="selectedSlot.Name"
        :image="selectedSlot.NormalImage"
        :scale="2"
        image-type="ProductNormal"
      />
    </div>
    <div
      v-for="index in Array.from({ length: 4 - Object.keys(selectedSlots).length }, (_v, i) => i)"
      :key="`v-slot-quarter-image-single-${index}`"
      class="v-slot-quarter-image-single"
    >
      <icon-menu-slot-quarter />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProductInList } from '~types/menuStore'

import type { GUID } from '@arora/common'

defineProps<{
  selectedSlots: Record<GUID, ProductInList>
  selectSlotGroup: (key: GUID) => void
}>()

const { imageExists } = useImageInfo()
</script>

<style lang="scss">
@use '~/assets/variables';

.v-slot-quarter-image-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 2px;
  overflow: hidden;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  grid-template-areas:
    'top-left top-right'
    'bottom-left bottom-right';

  .v-slot-quarter-image-single {
    aspect-ratio: 1/1;
    overflow: hidden;

    cursor: pointer;

    &:nth-child(1) {
      grid-area: top-left;

      .v-img-fluid {
        transform: translate(25%, 25%);
      }

      svg {
        transform: translate(25%, 25%);
        scale: 2;
      }
    }

    &:nth-child(2) {
      grid-area: top-right;

      .v-img-fluid {
        transform: translate(-25%, 25%);
      }

      svg {
        transform: translate(-25%, 25%);
        scale: 2;
      }
    }

    &:nth-child(3) {
      grid-area: bottom-left;

      .v-img-fluid {
        transform: translate(25%, -25%);
      }

      svg {
        transform: translate(25%, -25%);
        scale: 2;
      }
    }

    &:nth-child(4) {
      grid-area: bottom-right;

      .v-img-fluid {
        transform: translate(-25%, -25%);
      }

      svg {
        transform: translate(-25%, -25%);
        scale: 2;
      }
    }
  }

  .v-img-fluid {
    height: 100%;
    width: auto;
  }
}
</style>
